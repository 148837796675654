@font-face {
  font-family: "Open Sans";
  src: url("/fonts/opensanslatin.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("/fonts/opensans500.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("/fonts/opensanslatinbold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

:root {
  /* Dark theme */
  --background-color: #1b1b1b;
  --raised-background-color: #222;
  --raised-background-color-two: #232428;
  --raised-background-color-three: #2e3040;
  --text-color: #eee;
  --primary-color: #7551cb;
  --buddy-color: #2e3040;
  --border-color: rgba(222, 222, 222, 0.08);
}

[data-theme="light"] {
  /* Light theme */
  --background-color: #efefef;
  --raised-background-color: #f0eaf5;
  --raised-background-color-two: #ede4f5;
  --raised-background-color-three: #e1d9e9;
  --text-color: #1b1b1b;
  --primary-color: #dbd7f0;
  --buddy-color: #ede4f5;
  --border-color: rgba(144, 144, 144, 1);
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-size: 15px;
  line-height: 24px;
  font-family: "Open Sans", serif, sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 1rem 0;
  line-height: 1.5;
}
h1 {
  font-size: 2rem;
  font-weight: 700;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
h3 {
  font-size: 1.17rem;
  font-weight: 700;
}

#__next {
  height: 100%;
}
* {
  box-sizing: border-box;
}
body {
  overflow-y: scroll;
}
#root {
  height: 100%;
}
a {
  color: #0070f3;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

.hero__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 3rem 2rem 3rem 2rem;
}
.hero__container {
  display: flex;
  width: 100%;
  max-width: 1280px;
  position: relative;
}
.hero__body {
  margin-bottom: 48px;
  color: var(--text-color);
  font-size: 1.2rem;
  line-height: 2rem;
}
.hero__title {
  line-height: 4.4rem;
  font-size: 3.375rem;
  font-weight: 700;
  margin: 0 0 30px 0;
  background: linear-gradient(270deg, #7551cb 3.49%, #5172c8 92.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero__title span {
  color: var(--text-color);
}
.hero__half {
  padding: 4rem 4rem 4rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 50%;
  min-width: 50%;
  position: relative;
}
.hero__half_image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.hero__svg_wrapper {
  height: 500px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero__svg_wrapper--auto {
  height: auto;
}
.hero__subtext {
  margin-top: 1rem;
  font-size: 1rem;
}
.hero__button_row {
  display: flex;
  align-items: center;
}
.hero__button_row__icons {
  display: flex;
  margin-left: 2rem;
}
.hero__button_row__icon {
  margin-right: 1.5rem;
}
.hero__button_row__icon--disabled {
  cursor: default;
  pointer-events: none;
}
.hero__button_row__icon svg:hover {
  opacity: 0.8;
  transition: 0.2s ease;
}
.hero__button_row__icon svg,
.hero__button_row__icon path {
  height: 2rem;
  width: auto;
  fill: var(--text-color);
}
.hero__button_row__icon--disabled svg,
.hero__button_row__icon--disabled path {
  fill: rgb(222, 222, 222);
}
@media screen and (max-width: 992px) {
  .hero__half {
    flex: 1;
  }
  .hero__title {
    line-height: 1.5;
    font-size: 2.5rem;
  }
  .hero__button_row {
    flex-direction: column;
    align-items: flex-start;
  }
  .hero__button_row__icons {
    margin-top: 1rem;
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .hero__wrapper {
    height: auto;
    padding: 4rem 2rem 0 2rem;
    margin-bottom: 0;
  }
  .hero__container {
    flex-direction: column;
    padding: 0 0 2rem 0;
  }
  .hero__body {
    font-size: 1rem;
    margin-bottom: 16px;
    line-height: 1.7rem;
  }
  .hero__title {
    line-height: 1.5;
    font-size: 2rem;
  }
  .hero__half {
    padding: 0 0 2rem 0;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
  .hero__half_image {
    justify-content: flex-start;
  }
  .hero__svg_wrapper {
    height: auto;
    min-height: 260px;
    width: 100%;
  }
  .hero__button_row {
    flex-direction: column;
    align-items: flex-start;
  }
  .hero__button_row svg {
    margin-top: 1rem;
  }
  .hero__button_row__icon {
    margin-right: 0.5rem;
  }
}

/* -- HOME FEATURES -- */
.features_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  justify-content: center;
}
.features_container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
}
.features_object__wrapper {
  width: 33%;
  padding: 0.5rem;
  transition: 0.2s ease;
  text-decoration: none;
  text-decoration: none;
  color: var(--text-color);
}
.features_object__wrapper:hover {
  transform: scale(1.05, 1.05);
}
.features_object__wrapper svg {
  height: 40px;
  width: 40px;
  margin: 24px 0;
  fill: var(--text-color);
}
.features_object__wrapper h3 {
  font-size: 2rem;
}
.features_object__container {
  padding: 1rem 2rem;
  background-color: var(--background-color);
  border-radius: 5px;
  height: 100%;
}

@media screen and (max-width: 1440px) {
  .features_object__wrapper {
    width: 33%;
  }
}
@media screen and (max-width: 992px) {
  .features_object__wrapper {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .features_object__wrapper {
    width: 100%;
  }
}

.usko_banner_text_mobile {
  display: none;
  padding-right: 50px;
}
.usko_banner_text_desktop {
  display: flex;
  align-items: center;
}
.usko_banner_button {
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  background: linear-gradient(270deg, #7551cb 3.49%, #5172c8 92.38%);
  color: var(--text-color);
  border: none;
  text-decoration: none;
  position: relative;
  margin-left: 2rem;
  font-weight: 700;
  white-space: nowrap;
}
.usko_banner_button:hover {
  text-decoration: none;
  transform: scale(1.1, 1.1);
  transition: transform 0.2s ease;
}
@media screen and (max-width: 1140px) {
  .usko_banner_text_mobile {
    display: block;
  }
  .usko_banner_text_desktop {
    display: none;
  }
  .usko_banner_button {
    margin-left: 0;
    margin-top: 1rem;
  }
}
